import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
export default function e() {
  return {
    validate: function validate(e) {
      return {
        valid: e.value === "" || /^\d+$/.test(e.value)
      };
    }
  };
}