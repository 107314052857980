import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import e from "../utils/format";
export default function t() {
  return {
    validate: function validate(t) {
      var o = "select" === t.element.tagName.toLowerCase() ? t.element.querySelectorAll("option:checked").length : t.elements.filter(function (e) {
        return e.checked;
      }).length;
      var s = t.options.min ? "".concat(t.options.min) : "";
      var n = t.options.max ? "".concat(t.options.max) : "";
      var a = t.l10n ? t.options.message || t.l10n.choice.default : t.options.message;
      var l = !(s && o < parseInt(s, 10) || n && o > parseInt(n, 10));

      switch (true) {
        case !!s && !!n:
          a = e(t.l10n ? t.l10n.choice.between : t.options.message, [s, n]);
          break;

        case !!s:
          a = e(t.l10n ? t.l10n.choice.more : t.options.message, s);
          break;

        case !!n:
          a = e(t.l10n ? t.l10n.choice.less : t.options.message, n);
          break;

        default:
          break;
      }

      return {
        message: a,
        valid: l
      };
    }
  };
}