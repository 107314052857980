import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
export default function a() {
  return {
    validate: function validate(a) {
      if (a.value === "") {
        return {
          valid: true
        };
      }

      var e = Object.assign({}, {
        decimalSeparator: ".",
        thousandsSeparator: ""
      }, a.options);
      var t = "".concat(a.value);

      if (t.substr(0, 1) === e.decimalSeparator) {
        t = "0".concat(e.decimalSeparator).concat(t.substr(1));
      } else if (t.substr(0, 2) === "-".concat(e.decimalSeparator)) {
        t = "-0".concat(e.decimalSeparator).concat(t.substr(2));
      }

      var r = e.decimalSeparator === "." ? "\\." : e.decimalSeparator;
      var s = e.thousandsSeparator === "." ? "\\." : e.thousandsSeparator;
      var i = new RegExp("^-?[0-9]{1,3}(".concat(s, "[0-9]{3})*(").concat(r, "[0-9]+)?$"));
      var o = new RegExp(s, "g");

      if (!i.test(t)) {
        return {
          valid: false
        };
      }

      if (s) {
        t = t.replace(o, "");
      }

      if (r) {
        t = t.replace(r, ".");
      }

      var l = parseFloat(t);
      return {
        valid: !isNaN(l) && isFinite(l)
      };
    }
  };
}