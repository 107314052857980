import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { AES, enc } from "crypto-js";
import { isEmpty, isObject } from "lodash";
var ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;
export var getData = function getData(key) {
  try {
    var userString = window.localStorage.getItem(key);
    var userArr = JSON.parse(userString);
    return userArr;
  } catch (err) {
    return new Object();
  }
};
export var deleteData = function deleteData(key) {
  window.localStorage.removeItem(key);
};
export var saveData = function saveData(key, data) {
  window.localStorage.removeItem(key);
  window.localStorage.setItem(key, JSON.stringify(data));
};
export var saveEncrypt = function saveEncrypt(sessionkey, data) {
  try {
    if (isEmpty(data) === false && isObject(data)) {
      var encrypted = AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
      saveData(sessionkey, encrypted);
    }
  } catch (error) {
    console.warn({
      error: error
    });
  }
};
export var getEncrypt = function getEncrypt(sessionkey) {
  try {
    var encrypted = getData(sessionkey);
    var decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
    return JSON.parse(decrypted.toString(enc.Utf8));
  } catch (error) {
    return {};
  }
};
export var getConfig = function getConfig(key) {
  var appConfig = getData("app-config");
  return appConfig[key] || null;
};
export default {
  saveData: saveData,
  getData: getData,
  deleteData: deleteData,
  saveEncrypt: saveEncrypt,
  getEncrypt: getEncrypt,
  getConfig: getConfig
};