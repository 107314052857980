import _defineProperty from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions, _mutations;

import objectPath from "object-path";
import merge from "deepmerge";
import config from "@/core/config/layout.config.json"; // action types

export var SET_LAYOUT_CONFIG = "setLayoutConfig";
export var RESET_LAYOUT_CONFIG = "resetLayoutConfig";
export var OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig";
export var OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig"; // mutation types

export default {
  state: {
    config: config,
    initial: config
  },
  getters: {
    /**
     * Get config from layout config
     * @param state
     * @returns {function(path, defaultValue): *}
     */
    layoutConfig: function layoutConfig(state) {
      return function (path, defaultValue) {
        return objectPath.get(state.config, path, defaultValue);
      };
    }
  },
  actions: (_actions = {}, _defineProperty(_actions, SET_LAYOUT_CONFIG, function (state, payload) {
    state.commit(SET_LAYOUT_CONFIG, payload);
  }), _defineProperty(_actions, RESET_LAYOUT_CONFIG, function (state) {
    state.commit(RESET_LAYOUT_CONFIG);
  }), _defineProperty(_actions, OVERRIDE_LAYOUT_CONFIG, function (state) {
    state.commit(OVERRIDE_LAYOUT_CONFIG);
  }), _defineProperty(_actions, OVERRIDE_PAGE_LAYOUT_CONFIG, function (state, payload) {
    state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
  }), _actions),
  mutations: (_mutations = {}, _defineProperty(_mutations, SET_LAYOUT_CONFIG, function (state, payload) {
    state.config = payload;
  }), _defineProperty(_mutations, RESET_LAYOUT_CONFIG, function (state) {
    state.config = Object.assign({}, state.initial);
  }), _defineProperty(_mutations, OVERRIDE_LAYOUT_CONFIG, function (state) {
    state.config = state.initial = Object.assign({}, state.initial, JSON.parse(localStorage.getItem("config")));
  }), _defineProperty(_mutations, OVERRIDE_PAGE_LAYOUT_CONFIG, function (state, payload) {
    state.config = merge(state.config, payload);
  }), _mutations)
};