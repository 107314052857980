import _defineProperty from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions, _mutations;

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service"; // action types

export var VERIFY_AUTH = "verifyAuth";
export var LOGIN = "login";
export var LOGOUT = "logout";
export var REFRESH_AUTH = "refreshAuth";
export var ENGINEER_LOGIN = "engineerLogin";
export var ENGINEER_LOGOUT = "engineerLogout"; // mutation types

export var PURGE_AUTH = "logOut";
export var SET_AUTH = "setUser";
export var SET_PASSWORD = "setPassword";
export var SET_ERROR = "setError";
var state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken()
};
var getters = {
  currentUser: function currentUser(state) {
    return state.user;
  },
  isAuthenticated: function isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

var commitErrors = function commitErrors(response) {
  var requestErrors = [];
  var data = response.data;

  if (data && data.message) {
    requestErrors.push(data.message);
  } else if (response && response.status === 422) {
    if (data.errors) {
      for (var error in data.errors) {
        if (data.errors[error]) {
          for (var i = data.errors[error].length - 1; i >= 0; i--) {
            requestErrors.push(data.errors[error][i]);
          }
        }
      }
    } else {
      for (var _error in data) {
        if (data[_error]) {
          for (var _i = data[_error].length - 1; _i >= 0; _i--) {
            requestErrors.push(data[_error][_i]);
          }
        }
      }
    }
  } else if (response && response.status === 207) {
    requestErrors.push(data.error);
  } else if (response && response.status === 401) {
    requestErrors.push(data.error);
  } else if (response && response.status === 500) {
    requestErrors.push(data.message);
  } else if (response && response.status === 400) {
    requestErrors.push(data.message);
  } else if (response && response.status === 404) {
    requestErrors.push("Sorry ! The page you requested was not found.");
  } else if (response && response.status === 405) {
    requestErrors.push(data.message);
  } else {
    requestErrors.push(response.toString());
  }

  return requestErrors;
};

var actions = (_actions = {}, _defineProperty(_actions, LOGIN, function (context, credentials) {
  context.commit(PURGE_AUTH);
  return new Promise(function (resolve, reject) {
    ApiService.post("auth/login", credentials).then(function (_ref) {
      var data = _ref.data;
      localStorage.clear();
      sessionStorage.clear();
      context.commit(SET_AUTH, data.data);
      resolve(data.data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, LOGOUT, function (context) {
  if (JwtService.getToken()) {
    ApiService.setHeader();
    ApiService.post("auth/logout").then(function () {
      context.commit(PURGE_AUTH);
    });
  } else {
    context.commit(PURGE_AUTH);
  }
}), _defineProperty(_actions, VERIFY_AUTH, function (context) {
  if (JwtService.getToken()) {
    ApiService.setHeader();
    ApiService.get("auth/verify").catch(function () {
      context.commit(PURGE_AUTH);
    });
  } else {
    context.commit(PURGE_AUTH);
  }
}), _defineProperty(_actions, REFRESH_AUTH, function (context) {
  if (JwtService.getToken()) {
    ApiService.setHeader();
    ApiService.post("auth/refresh-token").then(function (_ref2) {
      var data = _ref2.data;
      context.commit(SET_AUTH, data.data);
    }).catch(function () {
      context.commit(PURGE_AUTH);
    });
  } else {
    context.commit(PURGE_AUTH);
  }
}), _defineProperty(_actions, ENGINEER_LOGIN, function (context, credentials) {
  return new Promise(function (resolve, reject) {
    context.commit(PURGE_AUTH);
    ApiService.post("v1/engineer/auth/login", credentials).then(function (_ref3) {
      var data = _ref3.data;
      localStorage.clear();
      sessionStorage.clear();
      context.commit(SET_AUTH, data.data);
      resolve(data.data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, ENGINEER_LOGOUT, function (context) {
  if (JwtService.getToken()) {
    ApiService.setHeader();
    ApiService.post("v1/engineer/auth/logout").then(function () {
      context.commit(PURGE_AUTH);
    });
  } else {
    context.commit(PURGE_AUTH);
  }
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_ERROR, function (state, error) {
  state.errors = error;
}), _defineProperty(_mutations, SET_AUTH, function (state, data) {
  state.isAuthenticated = true;
  state.user = data.user;
  state.errors = {};
  JwtService.saveToken(data.auth, data.user, data.menu, data.permissions);
}), _defineProperty(_mutations, PURGE_AUTH, function (state) {
  state.isAuthenticated = false;
  state.user = {};
  state.errors = {};
  JwtService.destroyToken();
}), _mutations);
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};