import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.flags.js";
export default function e() {
  return {
    validate: function validate(e) {
      if (e.value === "") {
        return {
          valid: true
        };
      }

      var t = e.options.regexp;

      if (t instanceof RegExp) {
        return {
          valid: t.test(e.value)
        };
      } else {
        var n = t.toString();
        var o = e.options.flags ? new RegExp(n, e.options.flags) : new RegExp(n);
        return {
          valid: o.test(e.value)
        };
      }
    }
  };
}