import _defineProperty from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions, _mutations;

import LocalService from "@/core/services/local.service"; // action types

export var UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export var UPDATE_ACCOUNT_INFO = "updateUserAccountInfo"; // mutation types

export var SET_PERSONAL_INFO = "setPersonalInfo";
export var SET_ACCOUNT_INFO = "setAccountInfo";
var LoggedInUser = LocalService.getData("current_user") || new Object();
var state = {
  user_personal_info: {
    photo: LoggedInUser.profile_logo || "",
    name: LoggedInUser.first_name || "",
    surname: LoggedInUser.last_name || "",
    company_name: LoggedInUser.company_name || "",
    job: LoggedInUser.role || "",
    email: LoggedInUser.user_email || "",
    phone: LoggedInUser.phone_number || ""
  }
};
var getters = {
  currentUserPersonalInfo: function currentUserPersonalInfo(state) {
    return state.user_personal_info;
  }
};
var actions = (_actions = {}, _defineProperty(_actions, UPDATE_PERSONAL_INFO, function (context, payload) {
  context.commit(SET_PERSONAL_INFO, payload);
}), _defineProperty(_actions, UPDATE_ACCOUNT_INFO, function (context, payload) {
  context.commit(SET_ACCOUNT_INFO, payload);
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_PERSONAL_INFO, function (state, user_personal_info) {
  state.user_personal_info = user_personal_info;
}), _defineProperty(_mutations, SET_ACCOUNT_INFO, function (state, user_account_info) {
  state.user_account_info = user_account_info;
}), _mutations);
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};