import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
export default function a() {
  return {
    validate: function validate(a) {
      if (a.value === "") {
        return {
          valid: true
        };
      }

      var e = Object.assign({}, {
        decimalSeparator: ".",
        thousandsSeparator: ""
      }, a.options);
      var t = e.decimalSeparator === "." ? "\\." : e.decimalSeparator;
      var r = e.thousandsSeparator === "." ? "\\." : e.thousandsSeparator;
      var o = new RegExp("^-?[0-9]{1,3}(".concat(r, "[0-9]{3})*(").concat(t, "[0-9]+)?$"));
      var n = new RegExp(r, "g");
      var s = "".concat(a.value);

      if (!o.test(s)) {
        return {
          valid: false
        };
      }

      if (r) {
        s = s.replace(n, "");
      }

      if (t) {
        s = s.replace(t, ".");
      }

      var i = parseFloat(s);
      return {
        valid: !isNaN(i) && isFinite(i) && Math.floor(i) === i
      };
    }
  };
}