import "core-js/modules/es.json.stringify.js";
import { serialize, parse } from "cookie";
export var setCookie = function setCookie(param) {
  try {
    document.cookie = serialize(param.key, param.value, {
      maxAge: 60 * 60 * 24,
      path: "/"
    });
    return true;
  } catch (error) {
    return error;
  }
};
export var setTempCookie = function setTempCookie(param) {
  try {
    document.cookie = serialize(param.key, param.value, {
      maxAge: param.time,
      path: "/"
    });
    return true;
  } catch (error) {
    return error;
  }
};
export var setDataCookie = function setDataCookie(param, maxAge) {
  try {
    document.cookie = serialize(param.key, JSON.stringify(param.value), {
      maxAge: maxAge,
      path: "/"
    });
    return true;
  } catch (error) {
    return error;
  }
};
export var getDataCookie = function getDataCookie(key) {
  try {
    var result = parse(document.cookie);

    if (result[key]) {
      return JSON.parse(result[key]);
    }

    return new Object();
  } catch (error) {
    return error;
  }
};
export var deleteCookie = function deleteCookie(key) {
  try {
    document.cookie = serialize(key, null, {
      httpOnly: process.env.NODE_ENV != "development" ? true : false,
      sameSite: process.env.NODE_ENV != "development" ? true : false,
      secure: process.env.NODE_ENV != "development" ? true : false,
      maxAge: -(60 * 60 * 24),
      path: "/"
    });
    return true;
  } catch (error) {
    return error;
  }
};
export var setCookieWithOptions = function setCookieWithOptions(param, options) {
  try {
    document.cookie = serialize(param.key, param.value, options);
    return true;
  } catch (error) {
    return error;
  }
};
export var getCookie = function getCookie(key) {
  try {
    var result = parse(document.cookie);

    if (result[key]) {
      return result[key];
    }

    return new Object();
  } catch (error) {
    return error;
  }
};