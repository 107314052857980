import Vue from "vue";
export var InitializeError = function InitializeError(response) {
  var requestErrors = [];
  var data = response.data;

  if (data && data.message) {
    requestErrors.push(data.message);
  } else if (response && response.status === 422) {
    if (data.errors) {
      for (var error in data.errors) {
        if (data.errors[error]) {
          for (var i = data.errors[error].length - 1; i >= 0; i--) {
            requestErrors.push(data.errors[error][i]);
          }
        }
      }
    } else {
      for (var _error in data) {
        if (data[_error]) {
          for (var _i = data[_error].length - 1; _i >= 0; _i--) {
            requestErrors.push(data[_error][_i]);
          }
        }
      }
    }
  } else if (response && response.status === 207) {
    requestErrors.push(data.error);
  } else if (response && response.status === 401) {
    requestErrors.push(data.error);
  } else if (response && response.status === 500) {
    requestErrors.push(data.message);
  } else if (response && response.status === 400) {
    requestErrors.push(data.message);
  } else if (response && response.status === 404) {
    requestErrors.push("Sorry ! The page you requested was not found.");
  } else if (response && response.status === 405) {
    requestErrors.push(data.message);
  } else if (typeof response === "string") {
    requestErrors.push(response);
  } else {
    console.log({
      error: response
    });
    requestErrors.push("Something went wrong, Please try again later.");
  }

  return requestErrors;
};
export var ErrorEventBus = new Vue();
export var SuccessEventBus = new Vue();
export var ClearEventBus = new Vue();