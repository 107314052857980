import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default function e(e, t, r, c) {
  var n = (r.getAttribute("type") || "").toLowerCase();
  var a = r.tagName.toLowerCase();

  switch (a) {
    case "textarea":
      return r.value;

    case "select":
      var _e = r;
      var _t = _e.selectedIndex;
      return _t >= 0 ? _e.options.item(_t).value : "";

    case "input":
      if ("radio" === n || "checkbox" === n) {
        var _e2 = c.filter(function (e) {
          return e.checked;
        }).length;
        return _e2 === 0 ? "" : _e2 + "";
      } else {
        return r.value;
      }

    default:
      return "";
  }
}