import moment from "moment-timezone";
import { AES, enc } from "crypto-js";
import { isEmpty, isObject } from "lodash";
import { setCookie, deleteCookie, getCookie } from "@/core/plugins/bt-cookie";
import { saveData, deleteData, getData } from "@/core/services/local.service";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;
const ID_ENTITY_RELOAD = "entity_reload";
const ID_TOKEN_KEY = "access_token";
const ID_TOKEN_TYPE = "token_type";
const ID_TOKEN_EXPIRY = "expires_at";
const ID_CURRENT_USER = "_btusr_tmp";
const ID_MENU_LIST = "_btcsr_tmp";
const ID_PERMISSION_LIST = "_btpsr_tmp";
const ID_CLIENT_DETAILS = "_btcld_tmp";

const destroyAuth = () => {
  deleteCookie(ID_TOKEN_KEY);
  deleteCookie(ID_TOKEN_TYPE);
  deleteCookie(ID_TOKEN_EXPIRY);
  deleteCookie(ID_ENTITY_RELOAD);
  deleteData(ID_CURRENT_USER);
  deleteData(ID_MENU_LIST);
  deleteData(ID_PERMISSION_LIST);
  deleteData(ID_CLIENT_DETAILS);
};

const isTokenExist = () => {
  return new Promise((resolve) => {
    let status = true;
    let token = getCookie(ID_TOKEN_KEY);
    if (!token) {
      status = false;
      destroyAuth();
    }
    resolve(status);
  });
};

export const currentUser = () => {
  try {
    let encrypted = getData(ID_CURRENT_USER);
    let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
    return JSON.parse(decrypted.toString(enc.Utf8));
  } catch (error) {
    return {};
  }
};

export const setClientData = (data) => {
  try {
    encryptObject(ID_CLIENT_DETAILS, data);
  } catch (error) {
    return error;
  }
};

export const getClientData = () => {
  try {
    let encrypted = getData(ID_CLIENT_DETAILS);
    let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
    return JSON.parse(decrypted.toString(enc.Utf8));
  } catch (error) {
    return {};
  }
};

export const menuList = () => {
  try {
    let encrypted = getData(ID_MENU_LIST);
    let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
    return JSON.parse(decrypted.toString(enc.Utf8));
  } catch (error) {
    return [];
  }
};

export const permissionList = () => {
  try {
    let encrypted = getData(ID_PERMISSION_LIST);
    let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
    return JSON.parse(decrypted.toString(enc.Utf8));
  } catch (error) {
    return [];
  }
};

export const getToken = () => {
  return getCookie(ID_TOKEN_KEY);
};

export const getTokenExpiry = () => {
  return getCookie(ID_TOKEN_EXPIRY);
};

export const isTokenExpired = async () => {
  let expired = true;
  let tokenExist = await isTokenExist();
  let tokenExpiry = getCookie(ID_TOKEN_EXPIRY);
  if (tokenExist && tokenExpiry) {
    expired = false;
    let nowDate = moment();
    let expiryDate = moment(tokenExpiry);
    if (moment(nowDate).isAfter(expiryDate)) {
      expired = true;
      destroyAuth();
    }
  }
  return expired;
};

export const isAuthenticated = async () => {
  let authenticated = false;
  let tokenExist = await isTokenExist();
  let tokenExpiry = getCookie(ID_TOKEN_EXPIRY);
  if (tokenExist && tokenExpiry) {
    let nowDate = moment();
    let expiryDate = moment(tokenExpiry);
    if (moment(expiryDate).isAfter(nowDate)) {
      authenticated = true;
    } else {
      destroyAuth();
    }
  }
  return authenticated;
};

export const isEngineer = async () => {
  let User = currentUser();
  if (isEmpty(User) === false && isObject(User)) {
    if (User.channel === "engineer") {
      return true;
    }
    return false;
  }
  return false;
};

export const getTokenType = () => {
  return "bearer"; // getCookie(ID_TOKEN_TYPE);
};

const encryptObject = (sessionkey, data) => {
  try {
    if (isEmpty(data) === false && isObject(data)) {
      let encrypted = AES.encrypt(
        JSON.stringify(data),
        ENCRYPTION_KEY
      ).toString();
      saveData(sessionkey, encrypted);
    }
  } catch (error) {
    console.warn({ error });
  }
};

export const saveToken = (tokens, user, menu, permissions) => {
  setCookie({ key: ID_TOKEN_KEY, value: tokens.access_token });
  setCookie({ key: ID_TOKEN_TYPE, value: tokens.token_type });
  setCookie({ key: ID_TOKEN_EXPIRY, value: tokens.expires_in });
  encryptObject(ID_CURRENT_USER, user);
  encryptObject(ID_MENU_LIST, menu);
  encryptObject(ID_PERMISSION_LIST, permissions);
};

export const destroyToken = () => {
  destroyAuth();
};

export default {
  getToken,
  getTokenExpiry,
  isAuthenticated,
  isEngineer,
  isTokenExpired,
  currentUser,
  getTokenType,
  menuList,
  permissionList,
  saveToken,
  destroyToken,
  setClientData,
  getClientData,
};
