import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import e from "../utils/format";
export default function s() {
  return {
    validate: function validate(s) {
      if (s.value === "") {
        return {
          valid: true
        };
      }

      var a = Object.assign({}, {
        inclusive: true,
        message: ""
      }, s.options);
      var l = parseFloat("".concat(a.max).replace(",", "."));
      return a.inclusive ? {
        message: e(s.l10n ? a.message || s.l10n.lessThan.default : a.message, "".concat(l)),
        valid: parseFloat(s.value) <= l
      } : {
        message: e(s.l10n ? a.message || s.l10n.lessThan.notInclusive : a.message, "".concat(l)),
        valid: parseFloat(s.value) < l
      };
    }
  };
}