import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import e from "../utils/format";
export default function s() {
  var s = function s(e) {
    return parseFloat("".concat(e).replace(",", "."));
  };

  return {
    validate: function validate(a) {
      var t = a.value;

      if (t === "") {
        return {
          valid: true
        };
      }

      var n = Object.assign({}, {
        inclusive: true,
        message: ""
      }, a.options);
      var l = s(n.min);
      var o = s(n.max);
      return n.inclusive ? {
        message: e(a.l10n ? n.message || a.l10n.between.default : n.message, ["".concat(l), "".concat(o)]),
        valid: parseFloat(t) >= l && parseFloat(t) <= o
      } : {
        message: e(a.l10n ? n.message || a.l10n.between.notInclusive : n.message, ["".concat(l), "".concat(o)]),
        valid: parseFloat(t) > l && parseFloat(t) < o
      };
    }
  };
}