import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.error.cause.js";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios"; // import router from "@/router";

import JwtService from "@/core/services/jwt.service";
import { ErrorEventBus, SuccessEventBus, InitializeError } from "@/core/lib/message.lib";
import { TopBarLoaderEventBus } from "@/core/lib/topbar.loader.lib";
import { setCookie } from "@/core/plugins/bt-cookie";
/**
 * Service to call HTTP request via Axios
 */
// ** For Refreshing Token

var isAlreadyFetchingAccessToken = false;
var subscribers = [];
var authToken = JwtService.getToken();
var tokenType = JwtService.getTokenType();
var ApiService = {
  init: function init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.common["Accept"] = "application/json";
    Vue.axios.defaults.trailingSlash = false;
    var numberOfAjaxCAllPending = 0; // Add a request interceptor

    Vue.axios.interceptors.request.use(function (config) {
      if (config.url.endsWith("/")) {
        config.url = config.url.slice(0, -1);
      }

      numberOfAjaxCAllPending++;
      TopBarLoaderEventBus.$emit("start:loader");
      return config;
    }, function (error) {
      return Promise.reject(error);
    });
    Vue.axios.interceptors.response.use(function (response) {
      if (response.data && response.data.message && response.data.with_message) {
        SuccessEventBus.$emit("update:success", response.data.message);
      }

      numberOfAjaxCAllPending--;

      if (numberOfAjaxCAllPending == 0) {
        TopBarLoaderEventBus.$emit("done:loader");
      }

      return response;
    }, function (error) {
      numberOfAjaxCAllPending--;

      if (numberOfAjaxCAllPending == 0) {
        TopBarLoaderEventBus.$emit("fail:loader");
      }

      ErrorEventBus.$emit("update:error", InitializeError(error.response));
      /* Manage Refresh Token Begin */

      var config = error.config,
          response = error.response;
      var originalRequest = config; // ** if (status === 401) {

      if (authToken && tokenType && response && response.status === 401) {
        try {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            Vue.axios.post("auth/refresh-token").then(function (response) {
              var data = response.data.data;
              authToken = data.access_token;
              tokenType = data.token_type;
              setCookie({
                key: "access_token",
                value: data.access_token
              });
              setCookie({
                key: "expires_at",
                value: data.expires_in
              });
              setCookie({
                key: "token_type",
                value: data.token_type
              });
              subscribers = subscribers.filter(function (callback) {
                return callback(authToken);
              });
            }).catch(function () {
              authToken = null;
              tokenType = null;
              setCookie({
                key: "access_token",
                value: null
              });
              setCookie({
                key: "expires_at",
                value: null
              });
              setCookie({
                key: "token_type",
                value: null
              });
              JwtService.destroyToken();
              setTimeout(function () {
                window.location = process.env.VUE_APP_BASE_URL;
              }, 500);
            }).finally(function () {
              isAlreadyFetchingAccessToken = false;
              Vue.axios.defaults.headers.common["Authorization"] = "".concat(tokenType, " ").concat(authToken);
            });
          }

          var retryOriginalRequest = new Promise(function (resolve) {
            subscribers.push(function (accessToken) {
              originalRequest.headers.Authorization = "".concat(tokenType, " ").concat(accessToken);
              resolve(axios.request(originalRequest));
            });
          });
          return retryOriginalRequest;
        } catch (error) {
          authToken = null;
          tokenType = null;
          setCookie({
            key: "access_token",
            value: null
          });
          setCookie({
            key: "expires_at",
            value: null
          });
          setCookie({
            key: "token_type",
            value: null
          });
          JwtService.destroyToken();
          setTimeout(function () {
            window.location = process.env.VUE_APP_BASE_URL;
          }, 500);
        }
      }
      /* Manage Refresh Token End */


      return Promise.reject(error);
    });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader: function setHeader() {
    // const token = JwtService.getToken();
    if (authToken && typeof authToken === "string") {
      Vue.axios.defaults.headers.common["Authorization"] = "".concat(tokenType, " ").concat(authToken);
    } //Vue.axios.defaults.withCredentials = true;

  },

  /**
   * Set the custom HTTP request headers
   */
  setCustomHeader: function setCustomHeader(token) {
    if (token && typeof token === "string") {
      Vue.axios.defaults.headers.common["Authorization"] = "".concat(tokenType, " ").concat(token);
    } //Vue.axios.defaults.withCredentials = true;

  },
  query: function query(resource, data) {
    return Vue.axios.get(resource, {
      params: data
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get: function get(resource) {
    var slug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    return Vue.axios.get("".concat(resource, "/").concat(slug)).catch(function (error) {
      throw new Error("[KT] ApiService ".concat(error));
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post: function post(resource, params) {
    return Vue.axios.post(resource, params);
  },
  upload: function upload(resource, params) {
    return Vue.axios.post(resource, params, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  },
  download: function download(resource, params) {
    Vue.axios.defaults.headers.common["Accept"] = "*/*";
    return Vue.axios.get(resource, {
      responseType: "blob",
      params: params
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update: function update(resource, slug, params) {
    return Vue.axios.put("".concat(resource, "/").concat(slug), params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put: function put(resource, params) {
    return Vue.axios.put(resource, params);
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch: function patch(resource, params) {
    return Vue.axios.patch(resource, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete: function _delete(resource, data) {
    return Vue.axios.delete(resource, {
      data: data
    });
  }
};
export default ApiService;