import _defineProperty from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions, _mutations;

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import ApiService from "@/core/services/api.service";
export var GET_COUNTRY = "getCountry";
export var GET_STATE = "getState";
export var GET_CITY = "getCity";
export var GET_BRANDS = "getBrands";
export var GET_MANUFACTURER = "getManufacturer";
export var GET_UOM = "getUom";
export var GET_WAREHOUSES = "getWarehouses";
export var GET_SUPPLIERS = "getSuppliers";
export var GET_CUSTOMERS = "getCustomers";
export var GET_CATEGORY = "getCategory";
export var GET_TAXES = "getTaxes";
export var GET_LOCATIONS = "getLocations";
export var GET_ADJUSTMENT_REASONS = "getAdjustmentReasons";
export var SET_ERROR = "setError";
export var SET_MESSAGE = "setMessage";
export var SET_DATA = "setData";
export var SET_CC_PERSONS = "setCCPersons";
var state = {
  errors: {},
  message: {},
  data: [],
  cc_persons: []
};
var getters = {
  cc_persons: function cc_persons(state) {
    return state.cc_persons;
  }
};

var commitErrors = function commitErrors(response) {
  var requestErrors = [];

  if (response && response.status === 422) {
    for (var error in response.data) {
      if (response.data[error]) {
        for (var i = response.data[error].length - 1; i >= 0; i--) {
          requestErrors.push(response.data[error][i]);
        }
      }
    }
  } else if (response && response.status === 500) {
    requestErrors.push(response.data.message);
  } else {
    requestErrors.push(response.toString());
  }

  return requestErrors;
};

var actions = (_actions = {}, _defineProperty(_actions, SET_CC_PERSONS, function (state, payload) {
  state.commit(SET_CC_PERSONS, payload);
}), _defineProperty(_actions, GET_COUNTRY, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("countries", slug).then(function (_ref) {
      var data = _ref.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_STATE, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("states", slug).then(function (_ref2) {
      var data = _ref2.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_CITY, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("cities", slug).then(function (_ref3) {
      var data = _ref3.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_BRANDS, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("brand", slug).then(function (_ref4) {
      var data = _ref4.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_MANUFACTURER, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("manufacturer", slug).then(function (_ref5) {
      var data = _ref5.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_UOM, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("uom", slug).then(function (_ref6) {
      var data = _ref6.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_WAREHOUSES, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("warehouse", slug).then(function (_ref7) {
      var data = _ref7.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_SUPPLIERS, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("supplier", slug).then(function (_ref8) {
      var data = _ref8.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_CUSTOMERS, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("customers", slug).then(function (_ref9) {
      var data = _ref9.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_CATEGORY, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("category", slug).then(function (_ref10) {
      var data = _ref10.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_LOCATIONS, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("locations", slug).then(function (_ref11) {
      var data = _ref11.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_ADJUSTMENT_REASONS, function (context, slug) {
  return new Promise(function (resolve, reject) {
    ApiService.get("item-adjustments/reasons", slug).then(function (_ref12) {
      var data = _ref12.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET_TAXES, function (context) {
  return new Promise(function (resolve, reject) {
    ApiService.get("taxes").then(function (_ref13) {
      var data = _ref13.data;
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_CC_PERSONS, function (state, payload) {
  state.cc_persons = payload;
}), _defineProperty(_mutations, SET_ERROR, function (state, error) {
  state.errors = error;
}), _defineProperty(_mutations, SET_MESSAGE, function (state, data) {
  state.message = data;
}), _defineProperty(_mutations, SET_DATA, function (state, data) {
  state.data = data;
  state.errors = {};
  state.message = {};
}), _mutations);
export default {
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};